import {
  Heading4,
  Paragraph1,
  Paragraph3,
  PhoneIcon,
  PrimaryButton,
  Text,
  TextInput,
} from "@cakemembers/components-core";
import { validateFormData } from "@cakemembers/utils";
import { useSignIn } from "@clerk/nextjs";
import { useRef, useState } from "react";
import { zfd } from "zod-form-data";
import { ValidateCode } from "./ValidateCode";
import { isClerkAPIResponseError } from "@clerk/nextjs/errors";

export function PhoneLogin({
  signIn,
  isSignInLoaded,
  onStarted,
  onLogin,
}: {
  isSignInLoaded: boolean;
  signIn: ReturnType<typeof useSignIn>["signIn"];
  onLogin(createdSessionId: string): void;
  onStarted?(): void;
}) {
  const formRef = useRef<HTMLFormElement>(null);
  const [state, setState] = useState<
    "initial" | "start-verification" | "verify" | "code-entered"
  >("initial");
  const [error, setError] = useState<string>();
  const [formValid, setFormValid] = useState(false);
  const [phone, setPhone] = useState<string>();

  function handleChange() {
    console.log("form changed", formRef.current?.checkValidity());
    if (formRef && formRef.current) {
      const newFormValid = formRef.current.checkValidity();
      if (newFormValid !== formValid) {
        setFormValid(newFormValid);
      }
    }
  }

  async function handleSubmit(formData: FormData) {
    onStarted?.();
    console.log("starting sign in");
    setState("start-verification");
    setError(undefined);
    const data = validateFormData(
      formData,
      zfd.formData({
        phone: zfd.text(),
      })
    );

    setPhone(data.phone);
    await sendCode(data.phone);
  }

  async function sendCode(phone: string) {
    if (!signIn) {
      throw new Error("signin not available");
    }
    if (!isSignInLoaded) {
      throw new Error("signIn not loaded");
    }

    try {
      const createResult = await signIn.create({ identifier: phone });
      if (createResult.createdSessionId) {
        return onLogin(createResult.createdSessionId);
      }
      if (createResult.status === "needs_first_factor") {
        const firstFactor = createResult.supportedFirstFactors.find(
          (s) => s.strategy === "phone_code"
        );
        if (!firstFactor || firstFactor.strategy !== "phone_code") {
          throw new Error("SMS login not available");
        }

        await createResult.prepareFirstFactor(firstFactor);
        setState("verify");
      }
    } catch (err: any) {
      console.log("error with signin", (err as any).errors, err);
      if (
        err &&
        isClerkAPIResponseError(err) &&
        err.errors[0].code === "form_identifier_not_found"
      ) {
        setError(
          "Account not found or you have not yet enabled SMS login in your account settings."
        );
        setState("initial");
        return;
      }
      throw err;
    }
  }

  async function handleCodeEntered(code: string) {
    if (!signIn) {
      throw new Error("signup not loaded");
    }
    setError(undefined);
    setState("code-entered");
    if (!signIn) {
      throw new Error("signIn not loaded");
    }

    const result = await signIn.attemptFirstFactor({
      strategy: "phone_code",
      code,
    });
    console.log("status", result.status, result);
    if (!result.createdSessionId) {
      throw new Error("session id not created");
    }
    onLogin(result.createdSessionId);
  }

  if (state === "verify" || state === "code-entered") {
    return (
      <ValidateCode
        onCodeEntered={handleCodeEntered}
        heading={<Heading4>Verify Phone Number</Heading4>}
        paragraph={
          <Text>
            Check your messages for your 6 digit verification code. Didn&amp;t
            receive your verification code, try resending it.
          </Text>
        }
        error={error}
        loading={state === "code-entered"}
        onResend={() => sendCode(phone!)}
      />
    );
  }

  return (
    <form
      action={handleSubmit}
      ref={formRef}
      onChange={handleChange}
      className="flex flex-col gap-2"
    >
      <Heading4>Sign in to CAKE</Heading4>
      <Paragraph1>
        Enter your phone number to receive a login code or sign in another way
        below
      </Paragraph1>
      <TextInput
        icon={<PhoneIcon />}
        name="phone"
        label="Phone Number"
        pattern="[0-9]{3}-?[0-9]{3}-?[0-9]{4}"
        placeholder="123-456-7890"
        required
      />
      {error && (
        <Paragraph3 className="mt-3 text-secondary">{error}</Paragraph3>
      )}
      <div className="my-5 flex flex-col items-center justify-center gap-2">
        <PrimaryButton
          className="min-w-[200px]"
          type="submit"
          disabled={
            state === "start-verification" || !isSignInLoaded || !formValid
          }
          loading={state === "start-verification"}
          align="center"
        >
          Send Verification SMS
        </PrimaryButton>
      </div>
    </form>
  );
}
