"use client";

import {
  EmailOutlineIcon,
  LockIcon,
  LoginFacebookIcon,
  LoginGoogleIcon,
  PhoneIcon,
  SecondaryButton,
} from "@cakemembers/components-core";
import { useAuth, useSignIn } from "@clerk/nextjs";
import { useSearchParams } from "next/navigation";
import { useState } from "react";
import { AlreadyLoggedIn } from "./AlreadyLoggedIn";
import { MagicCodeLogin } from "./MagicCodeLogin";
import { PasswordLogin } from "./PasswordLogin";
import { PhoneLogin } from "./PhoneLogin";

const ERROR_EMAIL_EXISTS = "ERROR_EMAIL_EXISTS";

export type FirstFactorResult =
  | {
      status: "complete";
      createdSessionId: string;
    }
  | { status: "email_verification_sent" }
  | { status: "error"; error: typeof ERROR_EMAIL_EXISTS | any }
  | { status: "account_not_found" }
  | { status: "unknown" };

export type FirstFactorProps = {
  socialRedirectUrl: string;
  onAuthenticated?(
    mode: "already-authenticated" | "signup" | "signin",
    iam: string
  ): void;
};

export function LoginShell({
  socialRedirectUrl,
  onAuthenticated,
}: FirstFactorProps) {
  const [loginMethod, setLoginMethod] = useState<
    "password" | "magic-code" | "social" | "phone"
  >("password");
  const { isSignedIn } = useAuth();
  const { signIn, isLoaded: isSignInLoaded, setActive } = useSignIn();
  const [loading, setLoading] = useState(false);
  const sp = useSearchParams();

  // useEffect(() => {
  //   if (!started && isSignedIn) {
  //     onAuthenticated && onAuthenticated("already-authenticated", userId);
  //   }
  // }, [started, signIn, signIn?.status, setActive, isSignedIn]);

  async function authenticateSocial(
    strategy: "oauth_facebook" | "oauth_google"
  ) {
    if (!isSignInLoaded || !signIn) {
      throw new Error("signIn not loaded");
    }
    return signIn.authenticateWithRedirect({
      strategy,
      redirectUrl: socialRedirectUrl,
      redirectUrlComplete: socialRedirectUrl,
    });
  }

  if (isSignedIn && !loading) {
    return <AlreadyLoggedIn />;
  }

  return (
    <div className="max-w-[425px] flex flex-col gap-4">
      {loginMethod === "password" && (
        <PasswordLogin
          onLogin={(sessionId) => {
            setLoading(false);
            if (!setActive) {
              throw new Error("invalid login state");
            }
            setActive({ session: sessionId });
            onAuthenticated && onAuthenticated("signin", sessionId);
          }}
          signIn={signIn}
          isSignInLoaded={isSignInLoaded}
        />
      )}
      {loginMethod === "magic-code" && (
        <MagicCodeLogin
          onLogin={(sessionId) => {
            if (!setActive) {
              throw new Error("invalid login state");
            }
            setActive({ session: sessionId });
            onAuthenticated && onAuthenticated("signin", sessionId);
          }}
          signIn={signIn}
          isSignInLoaded={isSignInLoaded}
        />
      )}
      {loginMethod === "phone" && (
        <PhoneLogin
          onLogin={(sessionId) => {
            if (!setActive) {
              throw new Error("invalid login state");
            }
            setActive({ session: sessionId });
            onAuthenticated && onAuthenticated("signin", sessionId);
          }}
          signIn={signIn}
          isSignInLoaded={isSignInLoaded}
        />
      )}

      <div>
        <div className="flex items-center mb-4">
          <span className="flex-1 border-t border-gray-300"></span>
          <span className="px-4 text-gray-600">or</span>
          <span className="flex-1 border-t border-gray-300"></span>
        </div>
        <div className="flex flex-row gap-3 items-center justify-center">
          {loginMethod !== "password" && (
            <SecondaryButton
              className="w-full max-w-[110px]"
              disabled={loading || !isSignInLoaded}
              onClick={() => setLoginMethod("password")}
              align="center"
              icon={<LockIcon className="w-4 h-4" />}
            >
              Password
            </SecondaryButton>
          )}
          {loginMethod !== "magic-code" && (
            <SecondaryButton
              className="w-full max-w-[110px]"
              disabled={loading || !isSignInLoaded}
              onClick={() => setLoginMethod("magic-code")}
              align="center"
              icon={<EmailOutlineIcon className="w-4 h-4" />}
            >
              Email
            </SecondaryButton>
          )}
          {loginMethod !== "phone" && (
            <SecondaryButton
              className="w-full max-w-[110px]"
              disabled={loading || !isSignInLoaded}
              onClick={() => setLoginMethod("phone")}
              align="center"
              icon={<PhoneIcon className="w-3 h-3" />}
            >
              Text
            </SecondaryButton>
          )}
          {sp.get("test-fb") && (
            <form action={() => authenticateSocial("oauth_facebook")}>
              <SecondaryButton
                className="w-full max-w-[110px]"
                disabled={loading || !isSignInLoaded}
                icon={<LoginFacebookIcon />}
                align="center"
              >
                Facebook
              </SecondaryButton>
            </form>
          )}

          <form action={() => authenticateSocial("oauth_google")}>
            <SecondaryButton
              className="w-[110px]"
              disabled={loading || !isSignInLoaded}
              align="center"
              icon={
                <div className="flex items-center justify-center">
                  <LoginGoogleIcon className="w-4 h-4" />
                </div>
              }
            >
              Google
            </SecondaryButton>
          </form>
        </div>
      </div>
    </div>
  );
}
