"use client";
import {
  Heading4,
  Paragraph1,
  Paragraph3,
  PrimaryButton,
  Spinner,
  Text,
  TextInput,
} from "@cakemembers/components-core";
import { useSignIn } from "@clerk/nextjs";
import { useRouter } from "next/navigation";
import { FormEvent, useRef, useState } from "react";

export const ForgotPassword = () => {
  const router = useRouter();
  const formRef = useRef<HTMLFormElement>(null);
  const [formValid, setFormValid] = useState(false);
  const [pending, setPending] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [successfulCreation, setSuccessfulCreation] = useState(false);
  const [secondFactor, setSecondFactor] = useState(false);
  const [error, setError] = useState("");

  const { isLoaded, signIn, setActive } = useSignIn();

  if (!isLoaded) {
    return <Spinner />;
  }

  // Send the password reset code to the user's email
  async function create(e: FormEvent) {
    setPending(true);
    e.preventDefault();
    await signIn
      ?.create({
        strategy: "reset_password_email_code",
        identifier: email,
      })
      .then((_) => {
        setSuccessfulCreation(true);
        setError("");
        setPending(false);
      })
      .catch((err) => {
        console.error("error", err.errors[0].longMessage);
        setError(err.errors[0].longMessage);
        setPending(false);
      });
  }

  // Reset the user's password.
  // Upon successful reset, the user will be signed in
  async function reset(e: FormEvent) {
    setPending(true);
    e.preventDefault();
    await signIn
      ?.attemptFirstFactor({
        strategy: "reset_password_email_code",
        code,
        password,
      })
      .then((result) => {
        // Check if 2FA is required
        if (result.status === "needs_second_factor") {
          setSecondFactor(true);
          setError("");
        } else if (result.status === "complete") {
          // Set the active session to
          // the newly created session (user is now signed in)
          setActive({ session: result.createdSessionId });
          setError("");
          router.push("/home?modal=welcome-back");
        } else {
          console.log(result, "result reset password");
        }
        setPending(false);
      })
      .catch((err) => {
        console.error("error", err.errors[0].longMessage);
        setError(err.errors[0].longMessage);
        setPending(false);
      });
  }

  function handleChange() {
    if (formRef && formRef.current) {
      const newFormValid = formRef.current.checkValidity();
      if (newFormValid !== formValid) {
        setFormValid(newFormValid);
      }
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <Heading4>Forgot Password?</Heading4>
      <Paragraph1>
        {!successfulCreation
          ? "Enter your email in order to receive the password reset code"
          : "Enter new password and reset code that was sent to your email"}
      </Paragraph1>
      <form
        ref={formRef}
        onChange={handleChange}
        className="flex flex-col gap-4 items-center"
        onSubmit={!successfulCreation ? create : reset}
      >
        {!successfulCreation && (
          <>
            <TextInput
              label="Email"
              type="email"
              placeholder="john@doe.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <PrimaryButton
              disabled={pending || !formValid}
              loading={pending}
              className="w-fit my-5"
              align="center"
              type="submit"
            >
              Send password reset code
            </PrimaryButton>
            {error && (
              <Paragraph3 className="mt-3 text-secondary">{error}</Paragraph3>
            )}
          </>
        )}

        {successfulCreation && (
          <>
            <TextInput
              label="New password"
              name="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <TextInput
              label="Reset code"
              name="code"
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              required
            />
            <PrimaryButton
              disabled={pending || !formValid}
              loading={pending}
              type="submit"
            >
              Change password
            </PrimaryButton>
            {error && (
              <Paragraph3 className="mt-3 text-secondary">{error}</Paragraph3>
            )}
          </>
        )}

        {secondFactor && (
          <Text>2FA is required, but this UI does not handle that</Text>
        )}
      </form>
    </div>
  );
};
