import { SecondaryButton } from "@cakemembers/components-core";
import { useSignIn } from "@clerk/nextjs";
import { isClerkAPIResponseError } from "@clerk/nextjs/errors";
import { useEffect, useRef, useState } from "react";
import { zfd } from "zod-form-data";

import {
  EmailIcon,
  Heading4,
  LockIcon,
  Paragraph1,
  Paragraph3,
  PrimaryButton,
  TextInput,
} from "@cakemembers/components-core";
import { validateFormData } from "@cakemembers/utils";
import { useFormStatus } from "react-dom";
import { useInterval } from "usehooks-ts";
import { signupRescueAction } from "../actions";
import { ForgotPassword } from "./ForgotPassword";

export function PasswordLogin({
  signIn,
  isSignInLoaded,
  onStarted,
  onLogin,
}: {
  isSignInLoaded: boolean;
  signIn: ReturnType<typeof useSignIn>["signIn"];
  onLogin(createdSessionId: string): void;
  onStarted?(): void;
}) {
  const formRef = useRef<HTMLFormElement>(null);
  const [formValid, setFormValid] = useState(false);
  const [error, setError] = useState<string>();
  const [forgotPassword, setForgotPassword] = useState(false);

  useInterval(() => {
    if (formRef && formRef.current) {
      setFormValid(formRef.current.checkValidity());
    }
  }, 5 * 1000);

  useEffect(() => {
    // check if form is valid on mount
    if (formRef && formRef.current) {
      setFormValid(formRef.current.checkValidity());
    }
  }, [formRef]);

  async function handlePasswordSubmit(formData: FormData) {
    setError(undefined);
    if (!signIn) {
      throw new Error("signIn not loaded");
    }
    onStarted?.();

    const data = validateFormData(
      formData,
      zfd.formData({
        email: zfd.text(),
        password: zfd.text(),
      })
    );
    console.log("start sign in");

    try {
      const result = await signIn.create({
        strategy: "password",
        identifier: data.email,
        password: data.password,
      });
      console.log("create result", result);

      if (result.status !== "complete") {
        throw new Error("result is not complete");
      }
      if (!result.createdSessionId) {
        throw new Error("no session id");
      }

      onLogin(result.createdSessionId);
    } catch (err) {
      console.log("error", err);
      if (isClerkAPIResponseError(err)) {
        console.error("clerk error", err, err.errors[0].code);
        if (err.errors[0].code === "form_identifier_not_found") {
          try {
            const maybeToken = await signupRescueAction(
              data.email,
              data.password
            );

            if (!maybeToken) {
              setError("Incorrect email address or password");
              return;
            }
            const result = await signIn.create({
              strategy: "ticket",
              ticket: maybeToken,
            });
            if (result.createdSessionId) {
              onLogin(result.createdSessionId);
              return;
            } else {
              throw new Error("Failed to create session");
            }
          } catch (err) {
            // intentionally blank
          }
          setError("Incorrect email address or password");
        } else if (err.errors[0].code === "form_password_incorrect") {
          setError("Incorrect email address or password");
        } else if (err.errors[0].code === "session_exists") {
          setError("Already logged in");
        } else {
          setError("Error logging in");
        }
      } else {
        console.error("unknown error", err);
        setError("Error logging in");
      }
    }
  }

  function handleChange() {
    if (formRef && formRef.current) {
      const newFormValid = formRef.current.checkValidity();
      if (newFormValid !== formValid) {
        setFormValid(newFormValid);
      }
    }
  }

  function onForgotPasswordClick() {
    setForgotPassword(true);
  }

  return forgotPassword ? (
    <ForgotPassword />
  ) : (
    <form
      ref={formRef}
      onChange={handleChange}
      action={handlePasswordSubmit}
      className="flex flex-col gap-2"
    >
      <LoginForm
        isSignInLoaded={isSignInLoaded}
        formValid={formValid}
        onForgotPasswordClick={onForgotPasswordClick}
        error={error}
      />
    </form>
  );
}

export function LoginForm({
  isSignInLoaded,
  formValid,
  onForgotPasswordClick,
  error,
}: {
  formValid: boolean;
  isSignInLoaded: boolean;
  onForgotPasswordClick: () => void;
  error?: string;
}) {
  const { pending } = useFormStatus();
  return (
    <>
      <Heading4>Sign in to CAKE </Heading4>
      <Paragraph1>
        Enter your email and password or use the other methods below to sign in
        to your account.
      </Paragraph1>
      <TextInput
        type="email"
        icon={<EmailIcon />}
        name={"email"}
        label="Email"
        placeholder="Email"
        disabled={pending}
        required
      />
      <TextInput
        type="password"
        icon={<LockIcon />}
        name={"password"}
        label="Password"
        placeholder="Password"
        disabled={pending}
        required
      />
      {error && (
        <Paragraph3 className="mt-3 text-secondary text-center">
          {error}
        </Paragraph3>
      )}
      <div className="my-5 flex flex-col items-center justify-center gap-3">
        <PrimaryButton
          className="min-w-[200px]"
          type="submit"
          disabled={pending || !isSignInLoaded || !formValid}
          loading={pending}
          align="center"
        >
          Sign In
        </PrimaryButton>
        {error && (
          <SecondaryButton
            className="min-w-[200px]"
            align="center"
            onClick={onForgotPasswordClick}
          >
            Forgot Password?
          </SecondaryButton>
        )}
      </div>
    </>
  );
}
