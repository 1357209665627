import {
  GhostButton,
  Paragraph1,
  Spinner,
  VerifyCode,
} from "@cakemembers/components-core";
import { useToast } from "@cakemembers/components-motion";
import classNames from "classnames";
import { on } from "events";
import { useState } from "react";

export function ValidateCode({
  loading,
  error,
  heading,
  paragraph = <p>Check your email inbox for your 6 digit verification code.</p>,
  onCodeEntered,
  onResend,
}: {
  heading?: React.ReactNode;
  paragraph?: React.ReactNode;
  loading?: boolean;
  error?: string;
  onCodeEntered(code: string): void;
  onResend(): Promise<void>;
}) {
  const [resendLoading, setResendLoading] = useState(false);
  const toast = useToast();
  async function handleResend() {
    setResendLoading(true);
    await onResend();
    toast.addToast("Code resent");
    setResendLoading(false);
  }
  return (
    <div>
      {heading}
      {paragraph}
      <div className="py-6">
        <VerifyCode
          name="code"
          digits={6}
          onCodeEntered={(code) => onCodeEntered(code)}
        />
      </div>
      <div
        className={classNames(
          "flex flex-row gap-2 items-center",
          !loading && "invisible"
        )}
      >
        <Spinner /> <Paragraph1>Logging in...</Paragraph1>
      </div>
      {error && <Paragraph1 className="text-secondary">{error}</Paragraph1>}
      <GhostButton
        loading={resendLoading}
        disabled={loading}
        onClick={handleResend}
      >
        Resend Code
      </GhostButton>
    </div>
  );
}
