"use client";

import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { LoginShell } from "../common/LoginShell";

export function SignIn() {
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();

  const processedRedirectUrl =
    pathname.includes("/sign-in") && !searchParams.get("redirect_url")
      ? "/home?modal=welcome-back"
      : `${window.location}`;

  function handleAuthenticated() {
    router.push(processedRedirectUrl);
  }

  return (
    <LoginShell
      onAuthenticated={handleAuthenticated}
      socialRedirectUrl={processedRedirectUrl}
    />
  );
}
